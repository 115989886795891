<template>
  <List el="xsm" style="padding: 32px">
    <!-- <Text el="h2">{{ $route.params.id }}</Text> -->
    <List el="column-between" class="v-center">
      <Text el="h3">Create Referrer</Text>
      <Icon name="close" @click="close" />
    </List>

    <div el="input-list">
      <label el="input">
        <input
          type="text"
          placeholder="First Name"
          v-model="referrer.fname"
          el
        />
      </label>
      <label el="input">
        <input
          type="text"
          placeholder="Last Name"
          v-model="referrer.lname"
          el
        />
      </label>
      <label el="input">
        <input type="text" placeholder="Phone" v-model="referrer.phone" el />
      </label>
      <label el="input">
        <input type="text" placeholder="Email" v-model="referrer.email" el />
      </label>
      <!-- <label el="input">
        <input
          type="text"
          placeholder="Bank Account Number"
          v-model="referrer.accountNum"
          el
        />
      </label>
      <label el="input">
        <input
          type="text"
          placeholder="Bank Routing Number"
          v-model="referrer.routingNum"
          el
        />
      </label> -->
      <label el="input">
        <div el="sm-txt label" style="margin-bottom: var(--space-xxxsm)">
          Notes
        </div>
        <textarea
          placeholder="Notes..."
          cols="30"
          rows="10"
          v-model="referrer.notes"
          el
        ></textarea>
      </label>
    </div>

    <List el="column-stretch xsm" style="margin-top: 32px">
      <Btn @click="createReferrer" v-if="validInput">Create</Btn>
      <Btn disabled v-else>Create</Btn>
    </List>
  </List>
</template>
<script>
// let forEach = require("lodash/forEach");
// let map = require("lodash/map");
// import { ref as vueRef, reactive } from "vue";
import router from "../router";
// import Icon from "@/components/btn/Icon.vue";
import { db, auth } from "@/firebase";
import { addDoc, collection } from "firebase/firestore";

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
      module: null,
      referrer: {
        fname: "",
        lname: "",
        phone: "",
        email: "",
        notes: "",
        status: "active",
        accountNum: "",
        routingNum: "",
      },
    };
  },
  methods: {
    close() {
      this.closeSection();
      router.push("/dashboard/referrals");
    },
    closeSection() {
      this.section.collapse = true;
    },
    async createReferrer() {
      var x = this;
      let now = Date.now();

      let data = {
        firstName: x.referrer.fname,
        lastName: x.referrer.lname,
        phone: x.referrer.phone,
        email: x.referrer.email,
        notes: x.referrer.notes,
        createdOn: now,
        createdBy: {
          uid: auth.currentUser.uid,
          displayName: auth.currentUser.displayName,
        },
        status: x.referrer.status,
        accountNum: x.referrer.accountNum,
        routingNum: x.referrer.routingNum,
        referredCountSET: 0,
        perStudentPayoutSET: 1000,
        lifetimeEarnings: 0,
        paidEarnings: 0,
      };
      console.log(data);
      const docRef = await addDoc(collection(db, "referrers"), data);
      console.log(
        "SET Referral Link: " +
          "https://alphaleaf.tech/forms/set/application/?ref=" +
          docRef.id
      );
    },
  },
  mounted() {},
  computed: {
    validInput() {
      let r = this.referrer;
      if (r.fname != "" && r.lname != "" && r.phone != "" && r.email != "") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags > * {
  margin: 3px;
  padding: 3px 13px;
  /* border: 1px solid var(--color-7); */
  justify-self: flex-start;
  border-radius: 100px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
  user-select: none;
  color: var(--color-65);
  -webkit-text-fill-color: currentColor;
}
.tags > .active {
  color: var(--color-white);
  background-color: var(--color-cloud);
}
</style>
